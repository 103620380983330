<!-- 考试管理首页 -->
<template>
  <div>
    <div class="main">
      <div style="font-size: 26px; font-weight: bold">考试阅卷</div>
      <div>
        <div>
          <!-- <el-button
            type="primary"
            style="height: 34px; line-height: 10px; margin-right: 67px"
            class="cinput"
            @click='dialogVisible=true'
            >+添加门店</el-button
          > -->
          <el-radio-group v-model="radio" style="height: 34px" class="cinput">
            <el-radio-button label="-1">待阅卷</el-radio-button>
            <el-radio-button label="1">已阅卷</el-radio-button>
            <!-- <el-radio-button label="学员"></el-radio-button>
            <el-radio-button label="院长"></el-radio-button>
            <el-radio-button label="审核员"></el-radio-button> -->
          </el-radio-group>
        </div>
        <div>
             <el-select
            v-model="levelId"
            placeholder="等级筛选"
            class="cinput ac"
            style="margin: 0 23px"
             @change="pagerole"
          >
            <el-option
              v-for="item in levelList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-cascader
            @change="pagerole"
            placeholder="地区选择"
            :options="citys"
            class="cinput ac"
             clearable
            v-model='code'
          ></el-cascader>
          <!-- <div style="width: 221px">
            <el-input
              v-model="input"
              placeholder="输入姓名"
              class="cinput ac"
              @keyup.enter.native="search"
            ></el-input>
            <div
              style="
                display: flex;
                height: 34px;
                width: 34px;
                align-items: center;
                background: #f7f7f7;
              "
            >
              <img
                v-if="isshow"
                src="../../images/search2.png"
                alt=""
                @click="search"
                style="background: #f7f7f7"
              />
              <img
                v-if="isshow == false"
                src="../../images/search1.png"
                alt=""
                style="background: #f7f7f7"
              />
            </div>
          </div> -->
        </div>
      </div>

      <el-table
        :header-cell-style="{ background: '#F7F7F7' }"
        :data="tableData"
        style="width: 100%; min-height: 600px"
      >
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="userLevelName" label="学员等级" align="center" width="180">
        </el-table-column>
        <el-table-column prop="startTime" align="center" label="考试时间">
        </el-table-column>
        <el-table-column prop="address" align="center" label="地区">
          <template slot-scope="scope"> 
            <div>
              <span>{{scope.row.provinceName}}-{{scope.row.cityName}}-{{scope.row.areaName}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center">
          <template slot-scope="scope">
            <div>
              <el-link type="primary" :underline="false" style="margin: 0 25px" @click='details(scope.row.id)' v-if='radio==-1'
                >阅卷</el-link
              >
              <el-link type="primary" :underline="false" style="margin: 0 25px" @click='details2(scope.row.id)' v-if='radio==1'
                >查看详情</el-link
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex; align-items: center; justify-content: center">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageIndex"
          :page-sizes="[10, 15, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      width="562px"
      :before-close="handleClose"
    >
      <div
        style="
          display: flex;
          align-items: center;
          font-size: 20px;
          font-weight: bold;
          margin-bottom:38px
        "
      >
        <div class="line"></div>
        <div>添加门店</div>
      </div>
     <el-form label-position="right" label-width="100px" >
  <el-form-item label="店名：">
    <el-input v-model="value"></el-input>
  </el-form-item>
  <el-form-item label="院长：">
    <div>悠米</div>
  </el-form-item>
  <el-form-item label="联系方式：">
    <el-input v-model="value"></el-input>
  </el-form-item>
   <el-form-item label="地址：">
    <el-input v-model="value" type='textarea' :rows="5"></el-input>
  </el-form-item>
</el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {cityList} from '../../utils/cityCode'
import {levelList,userExamList} from '../../apis/index'
export default {
  data() {
    return {
      pageIndex:1,
      pageSize:10,
      total:0,
      levelList:[],
      levelId:'',
      citys:'',
      code:'',
      radio1:'1',
      dialogVisible: false,
      options: [],
      value: "",
      isshow: false,
      isshow2: true,
      currentPage: 1,
      radio: "-1",
      input: "",
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
    };
  },
  watch: {
    input(newdata, olddata) {
      console.log(newdata);
      if (newdata) {
        this.isshow = true;
      } else {
        this.isshow = false;
      }
    },
    radio(){
      this.pagerole()
    }
    // radio(newdata, olddata) {
    //   if (newdata == "已发布") {
    //     this.isshow2 = true;
    //   } else {
    //     this.isshow2 = false;
    //   }
    // },
  },
  created(){
    let codes=cityList()//获取省市区数据
    this.citys=codes
    this.level()
    this.pagerole()
  },
  methods: {
    async level(){//获取等级列表
     const {data}=await levelList({roleId:3})
     if(data.code==200){
       
        const obj={
           id:'',
           name:'全部'
         }
       this.levelList=[obj,...data.data]
     }else{
       this.$message.error(data.msg)
     }
    },
      async pagerole(){//查询相应学员列表
      const params={
       
        pageIndex:this.pageIndex,
        pageSize:this.pageSize,
        levelId:this.levelId,
        province:this.code[0],
        city:this.code[1],
        area:this.code[2],
        scoredState:this.radio
      }
      const {data}=await userExamList(params)
      this.tableData=data.data.records
      this.total=Number(data.data.total)
    },
      details(id){//阅卷
          this.$router.push({path:'/marking',query:{id:id}})
      },
      details2(id){//查看详情
          this.$router.push({path:'/marking2',query:{id:id}})
      },
    handleClose() {
      this.dialogVisible = false;
    },
    search() {
      console.log("我是搜索");
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang='less' scoped>
/deep/.dialog-footer{
  text-align: center;
}
.inp /deep/.el-input__inner{
  border: none;
  font-size: 20px;
}
.inp2 /deep/.el-input__inner,/deep/.el-radio__label{
  // border: none;
  font-size: 20px;
}
.line {
  width: 9px;
  height: 22px;
  border-radius: 6px;
  background: #409eff;
  margin-right: 11px;
}
/deep/.el-dialog__body {
  padding: 30px 36px;
}
/deep/.el-radio-group span {
  width: 106px;
}
.main {
  // min-height: 800px;
  background: #fff;
  margin: 19px 0;
  padding: 23px;
  border-radius: 14px;
}
.main > div:first-child {
  font-size: 26px;
  // font-weight: bold;
}
.main > div:nth-child(2) {
  display: flex;
  justify-content: space-between;
  height: 34px;
  margin: 18px 0;
  div {
    display: flex;
    img {
      width: 25px;
      height: 25px;
    }
  }
}
/deep/.el-radio-button__inner {
  height: 34px;
  line-height: 10px;
}
.cinput /deep/.el-input__inner {
  height: 34px;
  line-height: 10px;
}
/deep/.el-table::before {
  height: 0;
}
.ac /deep/.el-input__inner {
  border: none;
  background: #f7f7f7;
  // margin-right: 11px;
}
.red {
  color: #ff1b0b;
}
</style>
